// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
// Vuetify
import { createVuetify } from 'vuetify';
export default createVuetify({
    theme: {
        themes: {
            dark: {
                colors: {
                    primary: '#DD0235',
                },
            },
            light: {
                colors: {
                    primary: '#DD0235',
                },
            },
        },
    },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
