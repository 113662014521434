import { ref } from 'vue';
import axios from '@/api';
export const useAxiosRequest = (config) => {
    const result = ref();
    const loading = ref(false);
    const error = ref();
    const request = (p, c) => {
        if (c)
            config = {
                ...config,
                ...c,
            };
        if (config.dynamicUrl && p) {
            config.url = config.dynamicUrl(p);
        }
        if (p && config.ignoreParamsKeys) {
            config.ignoreParamsKeys.forEach((key) => delete p[key]);
        }
        if (config.method === 'GET' || config.method === 'DELETE') {
            config.params = p;
        }
        else {
            config.data = p;
        }
        loading.value = true;
        return new Promise((resolve, reject) => {
            if (!config.url) {
                reject(new Error('config.url is not defined'));
                throw new Error('config.url is not defined');
            }
            axios
                .request(config)
                .then((r) => {
                result.value = r.data;
                resolve(result.value);
            })
                .catch((e) => {
                error.value = e.response?.data;
                reject(error.value);
            })
                .finally(() => {
                loading.value = false;
            });
        });
    };
    return {
        loading,
        error,
        result,
        request,
    };
};
