import { useAxiosRequest } from '@/composables/api.composable';
export var EDeliveryServiceStatus;
(function (EDeliveryServiceStatus) {
    EDeliveryServiceStatus[EDeliveryServiceStatus["Wait"] = 1] = "Wait";
    EDeliveryServiceStatus[EDeliveryServiceStatus["Process"] = 2] = "Process";
    EDeliveryServiceStatus[EDeliveryServiceStatus["Stock"] = 3] = "Stock";
    EDeliveryServiceStatus[EDeliveryServiceStatus["InPath"] = 4] = "InPath";
    EDeliveryServiceStatus[EDeliveryServiceStatus["Returned"] = 5] = "Returned";
    EDeliveryServiceStatus[EDeliveryServiceStatus["Destroy"] = 6] = "Destroy";
    EDeliveryServiceStatus[EDeliveryServiceStatus["CancelInProgress"] = 7] = "CancelInProgress";
    EDeliveryServiceStatus[EDeliveryServiceStatus["CancelInPlace"] = 8] = "CancelInPlace";
    EDeliveryServiceStatus[EDeliveryServiceStatus["Cancel"] = 9] = "Cancel";
    EDeliveryServiceStatus[EDeliveryServiceStatus["PartSuccess"] = 10] = "PartSuccess";
    EDeliveryServiceStatus[EDeliveryServiceStatus["Success"] = 11] = "Success";
})(EDeliveryServiceStatus || (EDeliveryServiceStatus = {}));
export const useDeliveryServicesListRequest = () => useAxiosRequest({
    url: '/delivery-services',
    method: 'GET',
});
export const useDeliveryServicesSetStatusRequest = () => useAxiosRequest({
    dynamicUrl: (p) => `/delivery-services/${p.serviceId}/status/${p.statusId}`,
    ignoreParamsKeys: ['serviceId', 'statusId'],
    method: 'PUT',
});
export const useDeliveryServicesPickupAddressRequest = () => useAxiosRequest({
    url: '/delivery-services/getAllPickupsAddress',
    method: 'GET',
});
export const useDeliveryServicesTakeRequest = () => useAxiosRequest({
    dynamicUrl: (p) => `/delivery-services/${p.serviceId}/take`,
    ignoreParamsKeys: ['serviceId'],
    method: 'GET',
});
