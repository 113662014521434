import { useAxiosRequest } from '@/composables/api.composable';
export const useUsersSendGeoRequest = () => useAxiosRequest({
    dynamicUrl: (p) => `/users/geo?lat=${p.lat}&lng=${p.lng}`,
    method: 'POST',
});
export const useUsersDutyToggleRequest = () => useAxiosRequest({
    url: '/users/duty',
    method: 'GET',
});
export const useUsersEditMeRequest = () => useAxiosRequest({
    url: '/users/editMe',
    method: 'POST',
});
