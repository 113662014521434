import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useAuthRequest, useAuthTokenCheckRequest, } from '@/api/auth.api';
import { useRouter } from 'vue-router';
export const useAuthStore = defineStore('auth', () => {
    const router = useRouter();
    const token = ref(localStorage.getItem('token'));
    const isLogin = ref(!!token.value);
    const currentUser = ref();
    const currentAccount = ref(localStorage.getItem('currentAccount')
        ? JSON.parse(localStorage.getItem('currentAccount'))
        : null);
    const { request: loginRequest, loading: loginLoading, error: loginError, } = useAuthRequest();
    const { result: checkTokenResult, request: checkTokenRequest, loading: checkTokenLoading, error: checkTokenError, } = useAuthTokenCheckRequest();
    function login(username, password) {
        const basicToken = btoa(`${username}:${password}`);
        return loginRequest(undefined, {
            headers: {
                Authorization: `Basic ${basicToken}`,
                'X-Tg-InitData': window.Telegram.WebApp.initData,
            },
        }).then((r) => {
            token.value = r.payload.accounts[0].token;
            currentAccount.value = r.payload.accounts[0];
        });
    }
    function checkToken() {
        return checkTokenRequest(undefined, {
            headers: {
                'X-Auth-Token': token.value,
                'X-Tg-InitData': window.Telegram.WebApp.initData,
            },
        });
    }
    function logout() {
        token.value = null;
        router.push('/auth');
    }
    watch(checkTokenResult, (v) => {
        currentUser.value = v?.payload.user;
    });
    watch(token, (v) => {
        if (v) {
            localStorage.setItem('token', v);
        }
        else {
            localStorage.removeItem('token');
        }
        isLogin.value = !!v;
    });
    watch(currentAccount, (v) => {
        if (v) {
            localStorage.setItem('currentAccount', JSON.stringify(v));
        }
        else {
            localStorage.removeItem('currentAccount');
        }
    });
    return {
        token,
        currentAccount,
        currentUser,
        loginError,
        loginLoading,
        login,
        logout,
        checkTokenError,
        checkTokenLoading,
        checkToken,
    };
});
