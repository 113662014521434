import AuthPage from '@/pages/AuthPage.vue';
import DeliveryServicesPage from '@/pages/DeliveryServicesPage.vue';
import DeliveryServicesNewPage from '@/pages/DeliveryServicesNewPage.vue';
import UserProfilePage from '@/pages/UserProfilePage.vue';
import { createRouter, createWebHistory } from 'vue-router';
// import NotFoundView from "../views/NotFoundView.vue";
const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: 'delivery-services',
        },
    },
    {
        path: '/',
        redirect: {
            name: 'delivery-services',
        },
    },
    {
        path: '/delivery-services',
        name: 'delivery-services',
        component: DeliveryServicesPage,
    },
    {
        path: '/delivery-services-new',
        name: 'delivery-services-new',
        component: DeliveryServicesNewPage,
    },
    {
        path: '/profile',
        name: 'profile',
        component: UserProfilePage,
    },
    {
        path: '/auth',
        name: 'auth',
        meta: {
            layout: 'EmptyLayout',
        },
        component: AuthPage,
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;
