import { useDeliveryServicesListRequest, } from '@/api/deliveryServices.api';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
export const useDeliveryServicesListStore = defineStore('deliveryServicesList', () => {
    const { request: fetchList, result, loading, error, } = useDeliveryServicesListRequest();
    const items = ref([]);
    watch(result, (v) => {
        if (v?.payload) {
            items.value = v.payload.items;
        }
    });
    return {
        items,
        loading,
        error,
        fetchList,
    };
});
export const useDeliveryServicesDetailStore = defineStore('deliveryServicesDetail', () => {
    const data = ref();
    return {
        data,
    };
});
