import { useAuthStore } from '@/store/auth.store';
import axios from 'axios';
import { storeToRefs } from 'pinia';
// if (localStorage.getItem("jwtToken"))
//   axios.defaults.headers.common[
//     "Authorization"
//   ] = `Bearer ${localStorage.getItem("jwtToken")}`;
const instance = axios.create({
    baseURL: 'https://rxpert.ru/api/v1/',
    // baseURL: 'https://routes.expert/api/v1/',
});
instance.interceptors.request.use((config) => {
    const { token } = useAuthStore();
    if (!config.headers['X-Auth-Token']) {
        config.headers['X-Auth-Token'] = token;
    }
    return config;
});
instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const response = error.response;
    const requestConfig = error.config;
    if (response.status === 401 && window.location.pathname != '/auth') {
        const { token } = storeToRefs(useAuthStore());
        token.value = null;
        setTimeout(() => {
            document.location.href = '/auth';
        }, 250);
    }
    return Promise.reject(error);
});
export default instance;
